import { getAction, postAction } from "../index";
export let homerSelect = (params) => {
  return getAction("/index/newsreporting", params);
};
export let activitySelect = (params) => {
  return getAction("/index/activity", params);
};
export let policiesSelect = (params) => {
  return getAction("/index/policies", params);
};
export let hallSelect = (params) => {
  return getAction("/index/hall", params);
};
export let QuerypopularSelect = (params) => {
  return getAction("/movie/query/Popular", params);
};
export let MovieuptodateSelect = (params) => {
  return getAction("/movie/query/uptodate", params);
};
export let MovieboutiqueSelect = (params) => {
  return getAction("/movie/query/Boutique", params);
};
export let worksSelect = (params) => {
  return getAction("/index/works", params);
};
export let reportDetail = (params) => {
  return postAction("/details/newsreportingdetails", params);
};
export let activityDetail = (params) => {
  return postAction("/details/activitydetails", params);
};
export let worksDetail = (params) => {
  return postAction("/details/tworksdetails", params);
};
export let policiesDetail = (params) => {
  return postAction("/details/policiesdetails", params);
};
export let moviecatSelect = (params) => {
  return getAction("/movie/query/cat", params);
};
export let moviePlay = (params) => {
  return postAction("/movie/movie", params);
};
export let moviesPlay = (params) => {
  return postAction("/movie/movies", params);
};
export let moviechapter = (params) => {
  return postAction("/movie/chapter", params);
};
export let partySelect = (params) => {
  return getAction("/index/party", params);
};
export let partyDetail = (params) => {
  return postAction("/details/partydetails", params);
};

export const tupian = () => {
  return postAction("/certificate/lunbo");
};
