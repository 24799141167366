import { createStore } from "vuex";

export default createStore({
  state: {
    urlStatic: "https://bassociation.oss-cn-beijing.aliyuncs.com/",
    pageReport: 1,
    urlData: "", // 存储 URL 数据的状态
  },
  getters: {
    getPageReport(state) {
      return state.pageReport;
    },
  },
  mutations: {
    setPageReport(state, newPageReport) {
      state.pageReport = newPageReport;
    },
    setURLData(state, data) {
      state.urlData = data; // 更新 URL 数据的方法
    },
  },
  actions: {
    fetchURLData(context) {
      // 这里获取 URL 数据的逻辑，可以根据具体情况实现
      const url = window.location.href; // 举例：直接获取当前页面的 URL
      context.commit("setURLData", url); // 调用 mutation 更新 URL 数据
    },
  },
  modules: {},
});
