<!-- <template>
  <div>
    <div v-for="(item, index) in list" :key="item.id">
      {{ index }}--{{ item.goodName }}
      <el-button type="primary" @click="anniu">Primary</el-button>
    </div>
  </div>
</template>
<script>
import { selectGoods } from "../api/modules/test";
export default {
  name: "HomeView",
  data() {
    return {
      list: [],
    };
  },
  methods: {
    anniu() {
      alert("123");
    },
  },
  mounted() {
    // selectAll();
    selectGoods().then((res) => {
      console.log(res);
      this.list = res.data;
    });
  },
};
</script>
<style scoped></style> -->
<template>
  <div class="flex flex-wrap items-center">
    <el-dropdown>
      <!-- <el-button type="primary">
        <el-icon class="el-icon--right"><arrow-down /></el-icon>
      </el-button> -->
      <div>Dropdown List</div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item>Action 1</el-dropdown-item>
          <el-dropdown-item>Action 2</el-dropdown-item>
          <el-dropdown-item>Action 3</el-dropdown-item>
          <el-dropdown-item>Action 4</el-dropdown-item>
          <el-dropdown-item>Action 5</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-dropdown split-button type="primary" @click="handleClick">
      Dropdown List
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item>Action 1</el-dropdown-item>
          <el-dropdown-item>Action 2</el-dropdown-item>
          <el-dropdown-item>Action 3</el-dropdown-item>
          <el-dropdown-item>Action 4</el-dropdown-item>
          <el-dropdown-item>Action 5</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup>
import { ArrowDown } from "@element-plus/icons-vue";

const handleClick = () => {
  // eslint-disable-next-line no-alert
  alert("button click");
};
</script>
<style scoped>
.example-showcase .el-dropdown + .el-dropdown {
  margin-left: 15px;
}

.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}
</style>
