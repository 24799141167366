import axios from "axios";
// import router from "../router/index";
let config = {
  // baseURL: "https://5394a009s6.goho.co",
  baseURL: "http://101.201.60.139",
  // baseURL: "http://127.0.0.1:5000",
  timeout: 20000, // 将超时时间调整为 20 秒
  headers: {
    Accept:
      "text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8",
    "Content-Type": "application/json", // 设置请求头部的Content-Type
    // 其他自定义请求头部...
  },
};
let service = axios.create(config);
export default service;
