import { getAction, postAction } from "../index";
export let fenhuiSelect = (params) => {
  return getAction("/user/fenhui", params);
};
export let registerPerson = (params) => {
  return postAction("/user/register", params, {
    // headers: {
    //   "Content-Type": "multipart/form-data", // 设置请求头
    //   // 在这里可以添加其他请求头信息
    // },
    headers: {
      "Content-Type": "application/json",
    },
  });
};
export let registerGroup = (params) => {
  return postAction("/user/registers", params, {
    headers: {
      "Content-Type": "application/json", // 设置请求头
      // 在这里可以添加其他请求头信息
    },
  });
};
// 个人上传申请表接口
export let applyuploadPerson = (params) => {
  return postAction("/businessapplication/update", params, {
    headers: {
      "Content-Type": "multipart/form-data", // 设置请求头
      // 在这里可以添加其他请求头信息
    },
  });
};
// 个人下载申请表接口
export let applydownPerson = (params) => {
  return postAction("/user/registers", params, {
    headers: {
      "Content-Type": "multipart/form-data", // 设置请求头
      // 在这里可以添加其他请求头信息
    },
  });
};
// 团体上传申请表接口
export let applyuploadGroup = (params) => {
  return postAction("/businessapplication/update", params, {
    headers: {
      "Content-Type": "multipart/form-data", // 设置请求头
      // 在这里可以添加其他请求头信息
    },
  });
};
// 团体下载申请表接口
export let applydownGroup = (params) => {
  return postAction("/user/registers", params, {
    headers: {
      "Content-Type": "multipart/form-data", // 设置请求头
      // 在这里可以添加其他请求头信息
    },
  });
};
// 获取JSSDK
export let getJSSDK = (params) => {
  return postAction("/weixinqianming/qianming", params, {
    headers: {
      "Content-Type": "application/json", // 设置请求头
      // 在这里可以添加其他请求头信息
    },
  });
};
