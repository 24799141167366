<template>
  <div>
    <Indexheader></Indexheader>
    <el-container>
      <el-main>
        <keep-alive>
          <router-view />
        </keep-alive>
      </el-main>
    </el-container>
    <!-- 页尾部 -->
    <Indexfooter></Indexfooter>
  </div>
</template>
<script setup>
import Indexheader from "@/components/home/header.vue";
import Indexfooter from "@/components/home/footer.vue";
import { provide, ref } from "vue";

const switchShow = ref(true);

provide("switchShow", switchShow);
</script>
<style scoped>
.el-main {
  padding: 0;
  overflow: revert;
}
</style>
