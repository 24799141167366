import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "element-plus/theme-chalk/index.css";
import ElementPlus from "element-plus";
import "dayjs/locale/zh-cn";
import locale from "element-plus/es/locale/lang/zh-cn";
import "@element-plus/icons-vue";

createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus, { locale })
  .mount("#app");
