<template>
  <div style="width: 100%">
    <div class="footer">
      <div class="footer_top">
        <div class="footer_top_left">
          <img
            style="width: 300px; margin-top: -8px"
            src="https://bassociation.oss-cn-beijing.aliyuncs.com/static/images/202408/1723450759461447257.png"
            alt="教育书画协会"
            v-if="switchShow"
          />
          <img
            src="https://bassociation.oss-cn-beijing.aliyuncs.com/static/images/202408/1723450851132412756.png"
            style="width: 300px; margin-top: -8px"
            v-else
          />
        </div>
        <div class="footer_top_center">
          <!-- <div style="line-height: 39px; margin-top: 49px">
            <p>网站备案:</p>
            <p>版权声明</p>
          </div>
          <div style="line-height: 39px; margin-left: 60px; margin-top: 49px">
            <p>友情链接</p>
            <p>联系我们</p>
          </div> -->
          <div class="footer_top_center_item">
            <span>网站备案</span>
            <span class="filings">京ICP备15049627号</span>
          </div>
          <!-- <div class="footer_top_center_item">版权声明</div> -->
          <div class="footer_top_center_item">
            <span class="women">联系我们</span>
            <span class="address">地址：北京市西城区西单大木仓胡同35号</span>
            <span class="zipCode">邮编：100816</span>
          </div>
        </div>
        <div class="footer_top_right">
          <img
            style="width: 88px; margin-top: 67px"
            src="../../assets/erweima.jpg"
          />
        </div>
      </div>
      <div class="footer_bottom">版权所有：教育书画协会</div>
    </div>
  </div>
</template>

<script setup>
import { inject } from "vue";

const switchShow = inject("switchShow");
console.log(switchShow);
</script>

<style scoped>
.filings {
  display: block;
  margin-top: 15px;
  margin-left: 1px;
}
.address {
  display: block;
  margin-top: 15px;
  margin-left: 110px;
}
.zipCode {
  margin-left: -80px;
}
.women {
  margin-left: -115px;
}
.footer {
  width: 100%;
  height: 265px;
  background-color: #545454;
  color: white;
}

.footer_top {
  width: 80%;
  height: 200px;
  margin: 5px auto;
  border-bottom: 1px solid white;
  display: flex;
}

.footer_bottom {
  text-align: center;
  font-size: 16px;
}

.footer_top_left {
  margin-top: 88px;
  margin-left: 4%;
}

.footer_top_center {
  width: 30%;
  height: 200px;
  margin-left: 4%;
  display: flex;
  flex: nowrap;
}

.footer_top_right {
  margin-left: 20%;
  width: 10%;
  height: 200px;
}
.footer_top_center_item:first-child {
  margin-left: 10px;
  width: 66px;
}
.footer_top_center_item {
  margin-left: 30px;
  margin-top: 73px;
  /* width: 70px; */
  white-space: nowrap;
}
@media (max-width: 1300px) {
  .footer {
    width: 1300px;
    height: 265px;
    background-color: #545454;
    color: white;
  }
}
/* 电脑端 */
@media (min-width: 1236px) {
  .footer {
    width: 100%;
    height: 265px;
    background-color: #545454;
    color: white;
  }
}
</style>
