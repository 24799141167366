import service from "../util/request";
import QS from "qs";
export function getAction(url, params) {
  return new Promise((resolve, reject) => {
    service
      .get(url, { params: params })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function postAction(url, params, headers) {
  return new Promise((resolve, reject) => {
    service
      .post(url, params, headers)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
