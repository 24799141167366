import { createRouter, createWebHistory } from "vue-router";
import home from "../views/home/home.vue";
import index from "../views/index.vue";
const routes = [
  // {
  //   path: "/",
  //   name: "index",
  //   component: index,
  // },
  {
    path: "/",

    redirect: "/home/homer?active=1",
    meta: {
      title: "首页",
    },
    component: home,

    children: [
      {
        path: "/home/homer",
        name: "homer",
        meta: {
          title: "首页",
        },
        component: () => import("@/views/home/homer.vue"),
      },
      {
        path: "home/homer1",
        name: "homer1",
        meta: {
          title: "首页",
        },
        component: () => import("@/views/home/homer1.vue"),
      },
      {
        path: "/introduceXH/intrXh",
        component: () => import("@/views/introduceXH/intrXh.vue"),
      },
      {
        path: "/laws/policies",
        component: () => import("@/views/laws/policies.vue"),
      },
      {
        path: "/news/report",
        component: () => import("@/views/news/report.vue"),
      },
      {
        path: "/art/exhibition",
        component: () => import("@/views/art/exhibition.vue"),
      },
      {
        path: "/virtual/classroom",
        component: () => import("@/views/virtual/classroom.vue"),
      },
      {
        path: "/virtual/classroomMessage",
        component: () => import("@/views/virtual/classroom_message.vue"),
      },
      {
        path: "/famous/teachers",
        component: () => import("@/views/famous/teachers.vue"),
      },
      {
        path: "/laws/policiesMessage",
        component: () => import("@/views/laws/policies_message.vue"),
      },
      {
        path: "/news/reportMessage",
        component: () => import("@/views/news/report_message.vue"),
      },

      {
        path: "/party/work",
        component: () => import("@/views/party/work.vue"),
      },

      {
        path: "/art/exhibitionMessage",
        component: () => import("@/views/art/exhibition_message.vue"),
      },
      {
        path: "/person/payment",
        component: () => import("@/views/person/payment.vue"),
      },
      {
        path: "/person/paymentPage",
        component: () => import("@/views/person/paymentPage.vue"),
      },
      {
        path: "/person/personCenter",
        component: () => import("@/views/person/personCenter.vue"),
      },
      {
        path: "/person/groupCenter",
        component: () => import("@/views/person/groupCenter.vue"),
      },
      {
        path: "/person/yewuApplyFor",
        component: () => import("@/views/person/yewuApplyFor.vue"),
      },
      {
        path: "/person/yewuApplyForMsg",
        component: () => import("@/views/person/yewuApplyForMsg.vue"),
      },
      {
        path: "/person/zhengshuSelect",
        component: () => import("@/views/person/zhengshuSelect.vue"),
      },
      {
        path: "/register/registerPerson",
        component: () => import("@/views/register/registerPerson.vue"),
      },
      {
        path: "/register/registerGroup",
        component: () => import("@/views/register/registerGroup.vue"),
      },
      {
        path: "/party/workmsg",
        component: () => import("@/views/party/workMsg.vue"),
      },
      {
        path: "/membership/membershipUser",
        component: () => import("@/views/membership/membershipUser"),
        redirect: "/membership/membershipPerson",
        children: [
          {
            path: "/membership/membershipPerson",
            component: () => import("@/views/membership/membershipPerson"),
          },
          {
            path: "/membership/membershipGroup",
            component: () => import("@/views/membership/membershipGroup"),
          },
          {
            path: "/membership/membership_message",
            component: () => import("@/views/membership/membership_message"),
          },
        ],
      },
    ],
  },
  {
    path: "/branch/office",
    component: home,
    children: [
      {
        path: "/branch/officeList",
        component: () => import("@/views/branch/officeList.vue"),
      },
      {
        path: "/branch/officeactivities",
        component: () => import("@/views/branch/officeActivities.vue"),
      },
      {
        path: "/branch/officeActivitiesMsg",
        component: () => import("@/views/branch/officeActivitiesMsg.vue"),
      },
      {
        path: "/branch/officenotice",
        component: () => import("@/views/branch/officeNotice.vue"),
      },
      {
        path: "/branch/officeList/officeListMsg",
        component: () => import("@/views/branch/officeListMsg.vue"),
      },
    ],
  },
  {
    path: "/index",
    component: index,
  },
  {
    path: "/login",
    component: () => import("@/views/login/login.vue"),
  },
  {
    path: "/register/registerUser",
    component: () => import("@/views/register/registerUser.vue"),
  },
  {
    path: "/login/forgetPassword",
    component: () => import("@/views/login/forgetPassword"),
  },
  {
    path: "/person/editPassword",
    component: () => import("@/views/person/editPassword"),
  },
];

const router = createRouter({
  // process.env.BASE_URL
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.href == "/home/homer?active=1") {
    const active = to.query.active;
    localStorage.setItem("active", active);
    console.log("这是传递过来的参数", active);
  }

  next();
});
export default router;
